<template>
  <div class="py-3 flex flex-col gap-y-5">
    <!-- Avatar -->
    <div
      class="
        flex flex-row
        items-center
        gap-x-2
        border-0 border-b border-solid border-gray-200
        pb-4
        px-2
      "
    >
      <avatar-upload
        :param-file.sync="user.image"
        :active-upload="this.user._id === this.$auth.getUserId()"
        :run-save-function="onSaveAvatar"
        :reSize="true"
      ></avatar-upload>
      <div class="flex flex-col gap-y-3">
        <!-- Fullname -->
        <div class="flex flex-row gap-x-2">
          <!-- Mode Normal -->
          <h3 class="font-EffraR text-lg">
            {{ capitalize()(user.title) || "Dr." }}
            {{ user.firstName }}
            {{ user.lastName }}
          </h3>
        </div>
        <!-- Address -->
        <div class="flex flex-row gap-x-2">
          <h3 class="font-EffraR text-lg">
            {{ user.landingPage.address.label }}
            {{
              user.landingPage && user.landingPage.address2
                ? ", " + user.landingPage.address2
                : ""
            }}
            {{
              user.landingPage.address.city
                ? user.landingPage.address.city.name
                : ""
            }}
            {{ user.fix ? " - " + user.fix : "" }}
          </h3>
          <button
            @click="modeEdite('address')"
            class="bg-transparent border-0 cursor-pointer"
            v-if="modeEdit !== 'address'"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Address -->
    <div
      class="
        gap-x-2
        w-full
        px-2
        border-0 border-b border-solid border-gray-200
        pb-4
      "
      v-if="user.landingPage.address && modeEdit === 'address'"
    >
      <!-- Model Edité -->
      <div class="flex flex-col gap-y-3 w-full">
        <div>
          <label>Téléphone fix</label>
          <input
            placeholder="Téléphone fix"
            class="
              bg-white
              border border-solid border-gray-300
              px-1
              w-full
              py-3
              rounded-cu
            "
            v-model="user.fix"
          />
        </div>
        <div>
          <label>Ville</label>
          <CitiesSelect
            class="w-full doksingupselect"
            :selected-item.sync="user.landingPage.address.city"
          ></CitiesSelect>
        </div>
        <div>
          <label>Adresse</label>
          <div class="relative">
            <input
              placeholder="Adresse"
              class="
                bg-white
                border border-solid border-gray-300
                px-1
                w-full
                py-3
                rounded-cu
              "
              v-model="user.landingPage.address.label"
            />
            <span
              class="
                absolute
                bg-nblue
                right-0
                top-0
                h-full
                px-3
                flex
                justify-center
                items-center
                text-white
                cursor-pointer
                rounded-r-lg
              "
              @click="searchAddress"
            >
              <i class="fas fa-search"></i
            ></span>
          </div>
        </div>
        <div>
          <label>Complément d'adresse</label>
          <input
            placeholder="Complément d'adresse"
            class="
              bg-white
              border border-solid border-gray-300
              px-1
              w-full
              py-3
              rounded-cu
            "
            v-model="user.landingPage.address2"
          />
        </div>
        <div v-if="user.landingPage.address.city">
          <mapGoogle
            :lat="parseFloat(user.landingPage.address.lat) || 0"
            :lng="parseFloat(user.landingPage.address.lng) || 0"
            :set-position="setPosition"
            :zoom="10"
            class="h-500"
          ></mapGoogle>
          <p
            class="
              bg-dokBlue-lighter
              text-dokBlue-ultra
              font-EffraR
              text-lg text-white
              py-3
              px-2
              rounded-b-cu
              shadow-inner
            "
          >
            <i class="fas fa-info-circle"></i> Déplacez le curseur pour préciser
            votre localisation
          </p>
        </div>
        <div id="actions" class="flex justify-end items-center gap-x-3">
          <button
            @click="modeEdite('address')"
            class="
              border-2 border-solid border-dokBlue-ultra
              text-dokBlue-ultra
              hover:text-white
              bg-transparent
              hover:bg-dokBlue-ultra
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              cursor-pointer
              font-EffraR
              w-full
              xl:w-auto
            "
          >
            Sauvegarder
          </button>
          <button
            type="reset"
            @click="modeEdit = null"
            class="
              border-2 border-solid border-green-600
              text-green-600
              hover:text-white
              bg-transparent
              hover:bg-green-600
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              font-EffraR
              cursor-pointer
              w-full
              xl:w-auto
            "
          >
            Restaurer
          </button>
        </div>
      </div>
    </div>
    <!-- Spécialités -->
    <div
      class="
        flex
        items-center
        gap-x-2
        w-full
        px-2
        border-0 border-b border-solid border-gray-200
        pb-4
      "
    >
      <!-- Mode Normal -->
      <div class="flex justify-between w-full" v-if="modeEdit !== 'specialite'">
        <h3 class="font-EffraR text-lg flex-1">
          <b class="mr-1">Spécialités :</b>
          <font class="text-gray-700">{{
            user.landingPage.specialities.map(spec => spec.name).join(" - ")
          }}</font>
        </h3>
      </div>
    </div>
    <!-- Parcours -->
    <div
      class="
        flex
        items-center
        gap-x-2
        w-full
        px-2
        border-0 border-b border-solid border-gray-200
        pb-4
      "
    >
      <!-- Mode Normal -->
      <div class="flex justify-between w-full" v-if="modeEdit !== 'parcours'">
        <h3 class="font-EffraR text-lg flex-1">
          <b class="mr-1">Parcours :</b>
          <font class="text-gray-700">
            {{ user.landingPage.experienceBackground || "--" }}
          </font>
        </h3>
        <button
          @click="modeEdite('parcours')"
          class="bg-transparent border-0 cursor-pointer"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </div>
      <!-- Model Edité -->
      <div
        class="flex justify-between items-start w-full gap-x-2"
        v-if="modeEdit === 'parcours'"
      >
        <div class="flex items-center gap-x-3 w-full">
          <textarea
            placeholder="Écrivez quelques mots…"
            v-model="parcours"
            class="
              h-24
              border-grey
              rounded-cu
              px-1
              py-2
              placeholder-gray-600
              text-base
              w-full
              font-EffraR
              resize-none
            "
          ></textarea>
        </div>
        <div id="actions" class="flex items-center gap-x-3">
          <button
            @click="updateProfile()"
            class="
              border-2 border-solid border-dokBlue-ultra
              text-dokBlue-ultra
              hover:text-white
              bg-transparent
              hover:bg-dokBlue-ultra
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              cursor-pointer
              font-EffraR
              w-full
              xl:w-auto
            "
          >
            Sauvegarder
          </button>
          <button
            type="reset"
            @click="modeEdit = null"
            class="
              border-2 border-solid border-green-600
              text-green-600
              hover:text-white
              bg-transparent
              hover:bg-green-600
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              font-EffraR
              cursor-pointer
              w-full
              xl:w-auto
            "
          >
            Restaurer
          </button>
        </div>
      </div>
    </div>
    <!-- Diplômes -->
    <div
      class="
        flex
        items-center
        gap-x-2
        w-full
        px-2
        border-0 border-b border-solid border-gray-200
        pb-4
      "
    >
      <!-- Mode Normal -->
      <div class="flex justify-between w-full" v-if="modeEdit !== 'diplomes'">
        <h3 class="font-EffraR text-lg flex-1">
          <b class="mr-1">Diplômes :</b>
          <pre class="font-EffraR text-gray-700">
            {{ user.landingPage.experienceDiplomes || "--" }}
          </pre>
        </h3>
        <button
          @click="modeEdite('diplomes')"
          class="bg-transparent border-0 cursor-pointer"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </div>
      <!-- Model Edité -->
      <div
        class="flex justify-between items-start w-full gap-x-2"
        v-if="modeEdit === 'diplomes'"
      >
        <div class="flex items-center gap-x-3 w-full">
          <textarea
            placeholder="Vos diplômes…"
            v-model="diplomes"
            class="
              h-24
              border-grey
              rounded-cu
              px-1
              py-2
              placeholder-gray-600
              text-base
              w-full
              font-EffraR
              resize-none
            "
          ></textarea>
        </div>
        <div id="actions" class="flex items-center gap-x-3">
          <button
            @click="updateProfile()"
            class="
              border-2 border-solid border-dokBlue-ultra
              text-dokBlue-ultra
              hover:text-white
              bg-transparent
              hover:bg-dokBlue-ultra
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              cursor-pointer
              font-EffraR
              w-full
              xl:w-auto
            "
          >
            Sauvegarder
          </button>
          <button
            type="reset"
            @click="modeEdit = null"
            class="
              border-2 border-solid border-green-600
              text-green-600
              hover:text-white
              bg-transparent
              hover:bg-green-600
              py-3
              px-3
              rounded-cu
              transition
              delay-75
              duration-100
              ease-in-out
              font-EffraR
              cursor-pointer
              w-full
              xl:w-auto
            "
          >
            Restaurer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from "lodash";
import { EventBus } from "@/event-bus";
const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");
const mapGoogle = () => import("@/views/global-components/map/index");
const CitiesSelect = () => import("@/views/global-components/CitiesSelect");

export default {
  data() {
    return {
      user: this.$auth.getUserInfo(),
      modeEdit: null,
      parcours: "",
      diplomes: ""
    };
  },
  components: {
    avatarUpload,
    mapGoogle,
    CitiesSelect
  },
  mounted() {
    this.$store.dispatch("doctor/GET_BY_ID", {
      onData: this.onData,
      id: this.user._id
    });
  },
  methods: {
    capitalize() {
      return capitalize;
    },
    async searchAddress() {
      if (!this.user.landingPage.address.label) return;
      const { data } = await this.$http.get("/location", {
        params: {
          term: `${this.user.landingPage.address.label}, ${this.user.landingPage.address.city.name}`
        }
      });
      if (data.ok) {
        if (data.data.status === "ZERO_RESULTS") {
          const { data: responseLocal } = await this.$http.get("/get_ip");
          if (responseLocal.ok) {
            this.user.landingPage.address.lat = responseLocal.data.ll[0];
            this.user.landingPage.address.lng = responseLocal.data.ll[1];
            this.$vs.notify({
              time: 4000,
              text: "Success Set Currect Address",
              color: "success",
              iconPack: "feather",
              icon: "icon-check"
            });
          }
          return this.$forceUpdate();
        }
        if (data.data.results[0]) {
          this.user.landingPage.address.lat =
            data.data.results[0].geometry.location.lat;
          this.user.landingPage.address.lng =
            data.data.results[0].geometry.location.lng;
        }
        this.$forceUpdate();
      }
    },

    onSaveAvatar() {
      this.$store.dispatch("user/UPDATE_PROFILE", {
        data: {
          image: this.user.image
        },
        onData: ({ ok, data }) => {
          if (ok) {
            this.$vs.loading.close();
            this.reloadLocalStorage(data);
            this.$vs.notify({
              time: 4000,
              text: "La photo personnelle a bien été modifiée",
              color: "success",
              iconPack: "feather",
              icon: "icon-check"
            });
          }
        }
      });
    },

    setPosition({ lat, lng }) {
      this.user.landingPage.address.lat = lat;
      this.user.landingPage.address.lng = lng;
      this.$vs.notify({
        time: 4000,
        // title: "Success",
        text: "localisation précisée avec succès",
        color: "success",
        iconPack: "feather",
        icon: "icon-check"
      });
    },

    modeEdite(type) {
      if (this.user._id !== this.$auth.getUserId())
        return alert(
          "!!!Vous n'avez pas le droit d'effectuer cette action !!!"
        );
      if (type == "location") {
        if (
          this.user.landingPage.address.label &&
          this.user.landingPage.address.city
        ) {
          this.searchAddress();
        }
      }
      /*if (this.modeEdit === type || this.modeEdite === "other") {
        this.$vs.loading();
        this.$store.dispatch("user/UPDATE_PROFILE", {
          data: {
            landingPage: this.user.landingPage,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            title: this.user.title,
            fix: this.user.fix,
          },
          onData: this.saveOnData,
        });
        return (this.modeEdit = null);
      }*/
      this.modeEdit = type;
    },
    saveOnData({ ok, data }) {
      if (ok) {
        this.reloadLocalStorage(data);
        this.$vs.notify({
          time: 4000,
          text: "Profil mis à jour avec succès",
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        this.$vs.loading.close();
      }
    },

    onData({ ok, data }) {
      if (ok) this.user = data;
      this.isLoading = false;
    },

    reloadLocalStorage(data) {
      if (data._id) this.$auth.setUserInfo(data);
      EventBus.$emit("reloadProfileData");
    },

    editDoctorProfile(type) {
      if (this.user._id !== this.$auth.getUserId()) {
        return alert(
          "!!!Vous n'avez pas le droit d'effectuer cette action !!!"
        );
      } else {
        this.modeEdit = type;
      }
    },

    async updateProfile() {
      const { ok, data } = await this.$store.dispatch(
        "pharmacy/UPDATE_PROFILE",
        {
          parcours: this.parcours,
          diplomes: this.diplomes
        }
      );
      if (ok) {
        this.user.landingPage.experienceBackground = this.diplomes;
        this.user.landingPage.experienceDiplomes = this.parcours;
        this.modeEdit = null;
      }
    }
  }
};
</script>

<style>
.doksingupselect .multiselect__tag-icon:hover {
  background: #97b5ff !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tag {
  background: #e2e8f0 !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tags {
  min-height: 50px !important;
}

.doksingupselect .multiselect__tags {
  border: 2px solid #e2e8f0 !important;
}

.doksingupselect .multiselect__select {
  height: 45px !important;
}

.doksingupselect .multiselect__placeholder {
  padding-top: 5px !important;
  @apply text-gray-600 text-lg;
}

.doksingupselect .multiselect__input,
.doksingupselect .multiselect__single {
  line-height: 30px;
  @apply font-EffraR;
}

.doksingupselect .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.doksingupselect .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
